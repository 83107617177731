<template>
  <content-template
    :contained="containTemplate"
    :navigation="interiorNavigationItems"
  >
    <router-view />
  </content-template>
</template>

<script>
import ContentTemplate from '../../components/ContentTemplate/ContentTemplate'
import interiorNavigationMixin from '../../mixins/interior-navigation-mixin'

export default {
  name: 'OperationsRoot',
  components: {
    ContentTemplate,
  },
  mixins: [
    interiorNavigationMixin,
  ],
  computed: {
    containTemplate () {
      return this.$route.meta && this.$route.meta.contained
    },
  },
}
</script>
